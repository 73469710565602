.bg1 {
  // width: 25%;
  margin: auto;
  .jobs {
    padding: 0.7rem;
    height: 4rem;
    width: 4rem;
    background-color: #0040a8;
    border-radius: 5px;
  }
}
.graphCol {
  border-radius: 10px;
  .graphdiv {
    border-radius: 10px;
    img {
      width: 100%;
      max-height: 100%;
      height: 448px;
    }
  }
}

.resetBtn {
  &:hover {
    background-color: #d00000 !important;
  }
}

.resumeBtn {
  &:hover {
    background-color: #ffba08 !important;
  }
}
