* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.containers {
  font-family: "Poppins", sans-serif;
  display: flex;
  flex-direction: row;
  margin: 0 !important;
  margin-right: 0 !important;
  padding: 0 !important;

  .left {
    @media screen and (min-width: 1073px) {
      background: url("../../resources/images/Login_1.png") center center/cover;
      height: 100vh;
      flex: 1;
    }
  }

  .right {
    background: #fff;
    flex: 1;
    height: 100vh;

    .signupFormContainer {
      position: relative;
      width: 40%;
      height: 87%;
      margin: 3rem auto;
      display: flex;
      flex-direction: column;
      padding-top: 9rem;
      .p {
        position: absolute;
        top: 0px;
        .noaccount {
          color: #969696;
          font-size: 12px;
          // font-family: "Poppins-Medium";
          a {
            font-family: "Poppins", sans-serif;
            text-decoration: none;
            color: #0040a8;
          }
        }
      }

      // justify-content: space-between;
      .h2 {
        // flex: 2;
        margin-bottom: 20px;

        .signupHeading {
          font-family: "Poppins", sans-serif;
          font-weight: 500;
          font-size: 25px;
        }
      }

      .form {
        .signupForm {
          .inputdiv {
            padding: 5rem auto !important;

            label {
              display: block;
              position: relative;
              input {
                width: 100%;
                border: none;
                background-color: #e9eff6;
                padding: 0.5rem 1rem;
                border-radius: 5px;
                margin-bottom: 10px;
                font-size: 13px;
                &:focus {
                  outline: none;
                }

                &::placeholder {
                  color: #000000;
                }
              }
              &:nth-child(1) {
                .eyeSvg {
                  cursor: pointer;
                  position: absolute;
                  right: 12px;
                  top: 7px;
                  .eye {
                    height: 13px;
                  }
                }
              }
            }

            .inputdiv1 {
              margin-bottom: 10px;
            }

            .inputdiv2 {
              margin-bottom: 15px;
            }
          }
        }
      }

      .pg {
        // flex: 1;
        position: absolute;
        bottom: 0px;

        .copyrightt {
          color: #969696;
          font-size: 10px;
        }
      }
      .errorex {
        color: red;
      }
    }
  }
}
