.rightContainer {
  padding: 0rem 3.5rem;
}

@media screen and (max-width: 1274px) {
  .container {
    .left {
      flex: 1.5 !important;
    }
  }
}
.add {
  height: 0.9rem;
  // margin-bottom: 0.1rem;
  // padding-bottom: 0.1rem;
  margin-right: 0.3rem;
}
.container {
  background-color: #f5f5f5;
}
.rightContainer {
  // padding-left: 3rem;
  .mainHeading {
    font-size: 30px;
    font-family: "Poppins-SemiBold";
    color: #363b64;
    padding: 30px 0px;
  }
}

.table {
  // margin: auto;
  // height: 23.2rem;
  font-family: "Poppins-SemiBold";
  width: 100%;
  // height: 30rem;
  .tableStart {
    border: #f5f5f5 !important;
    .tableTitle {
      background-color: #fcfcfc;
    }
    .tableBody {
      background-color: #ffffff;
    }
  }
}
.editSvg {
  height: 35px;
  width: 35px;
  padding: 7px;
  margin-right: 8px;
  border: 2px solid #0040a8;
  border-radius: 5px;
  align-items: center;
  cursor: pointer;
}
.deleteSvg {
  height: 35px;
  width: 35px;
  padding: 7px;
  border: 2px solid #ff0000;
  border-radius: 5px;
  align-items: center;
  cursor: pointer;
}
