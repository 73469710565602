.container {
  display: flex;
  color: #363b64;
  .nameNrole {
    // padding: 1.5rem;
    font-size: 10px;
    .name {
      font-family: "Poppins-SemiBold";
      margin-bottom: 0.2rem;

      text-align: right;
    }
    .role {
      // width: 100%;
      // text-align: right !important;
      float: right;
      .logout {
        background: transparent;
        border: none;
        color: #a098ae;
        font-family: "Poppins-Regular";
      }
      // float: right;
    }
  }
  .image {
    // width: fit-content;
    // padding: 1rem;
    .adminimage {
      height: 2.5rem;
    }
  }
}
