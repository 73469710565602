.frontRow {
  .front {
    font-family: "Poppins-Regular";
    color: #363b64 !important;
    font-weight: 600;
    font-size: 18px;
    // padding: 20px;
    padding-left: 15px;
  }
  .mainPiece {
    color: #0040a8;
    font-family: "Poppins-Regular";
  }
}

.input {
  &:focus {
    box-shadow: none !important;
  }
}
