.announcementDiv {
  &:hover,
  &:focus-within {
    .announcement {
      path {
        fill: #0040a8;
      }
    }
  }
}

.AuctionPageBtn {
  color: #0040a8 !important;
  &:hover {
    color: white !important;
  }
}
