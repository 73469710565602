.modalBody {
  margin: 1rem 4rem 6rem 4rem;
  box-shadow: 10px 10px 30px #00000008;
  background-color: #ffffff;
  max-width: 1150px !important;
  width: fit-content;
  border-radius: 10px;
  margin: 2rem 2rem;
  font-family: "Poppins-Regular";
  .subPieceCol {
    padding: 25px 0 0 30px;
    display: flex;
    flex-direction: column;
    align-content: space-between;
    align-items: flex-start;
    // border: 1px solid #f5f5f5;
    .subPiece {
      //   font-family: "Poppins-Medium";
      color: #363b64 !important;
      font-weight: 600;
      font-size: 18px;
      padding: 5px 5px 25px 0;
    }
    .sideLinks {
      display: block;
      width: 100%;
      text-align: left;
      margin-top: 5px;
      margin-bottom: 5px;
      text-decoration: none;
      color: #363b64 !important;
      font-weight: 600;
      font-size: 16px;
      padding: 12px 0;
      &:hover {
        background-color: #0040a8;
        color: #ffffff !important;
      }
    }
    .active {
      background-color: #0040a8;
      color: #ffffff !important;
    }
  }
}
