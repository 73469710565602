.privacypolicy {
  color: #a098ae;
  margin-left: 95px;
  font-size: 10px;
}
.listItem {
  &:hover {
    .active {
      span {
        color: #a098ae !important;
      }
    }
  }
}
