.container {
  padding: 2rem 2rem;
  .Container {
    background-color: #f5f5f5;
    padding: 0rem 2rem;
    .mainHeading {
      font-size: 2rem;
      font-family: "Poppins-SemiBold";
      color: #363b64;
      margin-bottom: 1.5rem;
    }
  }
  .formContainer {
    position: relative;
    background-color: #fff;
    border-radius: 10px;
    padding: 5% 2rem;
    font-family: "Poppins-medium";
    color: #363b64;
    font-size: 13px;
    margin: 2rem;
    .customFileInput {
      color: transparent;
    }
    .customFileInput::-webkit-file-upload-button {
      visibility: hidden;
    }
    .customFileInput::before {
      content: "";
      font-family: "Poppins-SemiBold";
      color: #363b64;
      display: inline-block;
      background: transparent;
      z-index: 3;
      // border: 1px solid #999;
      border-radius: 50%;
      padding: 50px 18px;
      height: 110px;
      width: 110px;
      border-radius: 50%;
      position: absolute;
      top: -60px;
      right: 70px;
      outline: none;
      white-space: nowrap;
      user-select: none;
      -webkit-user-select: none;
      cursor: pointer;
      font-size: 12px;
    }
    .customFileInput:hover::before {
      border-color: black;
    }
    .customFileInput:active {
      outline: 0;
    }
    .customFileInput:active::before {
      background: -webkit-linear-gradient(top, #e3e3e3, #f9f9f9);
    }

    .control {
      color: #363b64;
      font-size: 1rem;
      background: #f5f5f5;
      padding-left: 1rem;
      border: none;
      border-radius: 4px;
      &:focus {
        outline: none;
        box-shadow: none !important;
      }
    }
    .colorcontrol {
      position: absolute;
      top: 31px;
      right: 20px;
      padding: 0px;
      outline: none;
      border-radius: 5px;
      &::-webkit-color-swatch {
        border: none;
        outline: none;
      }
      &::-moz-color-swatch {
        border: none;
        outline: none;
      }
      &:focus {
        outline: none;
      }
    }
    .save {
      height: 0.8rem;
      margin-bottom: 0.2rem;
    }
  }

  .tabs {
    padding: 0 3.5rem;
    position: relative;
    .tab {
      background-color: #ffffff;
      border-radius: 10px;
      margin: 10px;
    }
    .tab1 {
      .list {
        border-radius: 10px;
      }
    }
    .tab2 {
      position: fixed;
      right: 0px;
      // left: 100px;
      padding: 0;
      height: 630px;
    }
  }
}

// .colorPicker {
//   box-shadow: #245fb1 1px 1px 12px !important;
//   position: fixed;
//   top: 0px;
//   right: 0px;
//   bottom: 0px;
//   left: 0px;
// }
