.cmodal {
  font-family: "Poppins-SemiBold";
  .Header {
    color: #363b64;
    font-family: "Poppins-Bold";
    display: inline;
  }
  .Subtitle {
    color: #a098ae;
    font-family: "Poppins-SemiBold";
  }
  .form {
    background-color: #ffffff;
    font-family: "Poppins-SemiBold";
    width: 100%;
    .formLabel {
      font-weight: 600;
      font-family: "Poppins-Medium";
      color: #363b64 !important;
      margin-top: 15px !important;
    }
    .formControl {
      font-family: "Poppins-Regular";
      background-color: #ffffff;
      color: #363b64;
      border: 1px solid #e3e3e3;
      border-radius: 8px;
      // width: 95%;
      margin-top: 14px;
      &:focus {
        box-shadow: none !important;
      }
      &::placeholder {
        font-size: 12px;
      }
    }
    .colorcontrol {
      position: absolute;
      top: 61px;
      right: 36px;
      padding: 0px;
      outline: 0;
    }
  }
}
