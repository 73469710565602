.container {
  padding: 2rem 2rem;
  .Container {
    background-color: #f5f5f5;
    padding: 0rem 2rem;
    .mainHeading {
      font-size: 25px;
      font-family: "Poppins-SemiBold";
      color: #363b64;
      // margin-bottom: 1.5rem;
    }
  }
  .formContainer {
    position: relative;
    background-color: #fff;
    border-radius: 10px;
    padding: 2% 2rem;
    padding-top: 3%;
    font-family: "Poppins-medium";
    color: #363b64;
    font-size: 13px;
    margin: 2rem;
    .customFileInput {
      color: transparent;
    }
    .customFileInput::-webkit-file-upload-button {
      visibility: hidden;
    }
    .customFileInput::before {
      content: "";
      font-family: "Poppins-SemiBold";
      color: #363b64;
      display: inline-block;
      background: transparent;
      z-index: 3;
      // border: 1px solid #999;
      border-radius: 50%;
      padding: 50px 18px;
      height: 110px;
      width: 110px;
      border-radius: 50%;
      position: absolute;
      top: -60px;
      right: 70px;
      outline: none;
      white-space: nowrap;
      user-select: none;
      -webkit-user-select: none;
      cursor: pointer;
      font-size: 12px;
    }
    .customFileInput:hover::before {
      border-color: black;
    }
    .customFileInput:active {
      outline: 0;
    }
    .customFileInput:active::before {
      background: -webkit-linear-gradient(top, #e3e3e3, #f9f9f9);
    }

    .control {
      color: #363b64;
      font-size: 13px;
      background: #f5f5f5;
      border: none;
      border-radius: 10px;
      &:focus {
        outline: none;
        box-shadow: none !important;
      }
      &[disabled] {
        opacity: 0.6;
        pointer-events: none;
      }
    }
    .save {
      height: 0.8rem;
      margin-bottom: 0.2rem;
    }
  }
}
