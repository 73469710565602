.Container {
  border-radius: 10px;
}

.add {
  height: 0.9rem;
  margin-bottom: 0.1rem;
  padding-bottom: 0.1rem;
  margin-right: 0.3rem;
  g {
    path {
      fill: #0040a8;
    }
  }
  // width: 0.8rem;
}
.rightContainer {
  // padding-left: 3rem;

  .mainHeading {
    font-size: 30px;
    font-family: "Poppins-SemiBold";
    color: #363b64;
  }
}
.tShirt {
  height: 130px;
  width: 130px;
  border-radius: 50%;
  float: right;
  position: relative;
  top: 10px;
  padding: 1rem;
}
.form {
  background-color: #ffffff;
  font-family: "Poppins-SemiBold";
  width: 100%;
  border-radius: 10px;
  box-shadow: 10px 10px 30px #00000008;
  .accordionContainer {
    padding: 0px 0px 0px 30px;
  }
}
.pieces {
  border: 1px solid #707070;
  padding: 0.2rem 1rem;
  border-radius: 5px;
  font-family: "Poppins-SemiBold";
}
.boxShadow {
  box-shadow: none;
  &::-webkit-scrollbar {
    display: none;
  }
}
